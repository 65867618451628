import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./Redux/Reducers/counterSlice"


const store = configureStore({
    reducer: {
        counter: counterReducer,
    }
})


export default store;